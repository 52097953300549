.five-features {
  display: flex;
  flex-direction: row;
  gap: 50px; }
  @media (max-width: 1199px) {
    .five-features {
      gap: 37.5px; } }
  @media (max-width: 991px) {
    .five-features {
      gap: 25px; } }
  @media (max-width: 767px) {
    .five-features {
      flex-direction: column; } }
  .five-features__box {
    display: block;
    width: 100%; }
    .five-features__box--small {
      height: fit-content;
      overflow: hidden;
      width: 46%; }
      @media (max-width: 767px) {
        .five-features__box--small {
          width: 100%; } }
  .five-features__box-wrapper {
    display: flex;
    flex: 1;
    max-width: 50%; }
    @media (max-width: 767px) {
      .five-features__box-wrapper {
        max-width: unset; } }
    .five-features__box-wrapper--multi {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: space-between; }
      @media (max-width: 767px) {
        .five-features__box-wrapper--multi {
          gap: 25px; } }
  .five-features__title {
    bottom: 0;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    left: 0;
    line-height: 28px;
    padding: 20px 60px 20px 20px;
    position: absolute;
    width: 100%;
    z-index: 1; }
    @media (max-width: 1919px) {
      .five-features__title {
        font-size: 20px;
        line-height: 24px; } }
    @media (max-width: 1199px) {
      .five-features__title {
        font-size: 16px;
        line-height: 20px;
        padding: 15px 40px 15px 15px; } }
    @media (max-width: 991px) {
      .five-features__title {
        font-size: 14px;
        line-height: 18px;
        padding: 10px 40px 10px 10px; } }
    .five-features__title--big {
      font-size: 30px;
      line-height: 36px;
      padding: 30px 60px 30px 30px; }
      @media (max-width: 991px) {
        .five-features__title--big {
          font-size: 24px;
          line-height: 28px; } }
    .five-features__title--top {
      bottom: unset;
      top: 0; }
    .five-features__title--bottom {
      bottom: 0; }
    @media (max-width: 767px) {
      .five-features__title {
        bottom: unset;
        font-size: 24px;
        line-height: 28px;
        padding: 30px 60px 30px 30px;
        top: 0; } }
